<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}
</style>
<template>
  <div>
    <CRow>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Reclamo Club Suscriptores</strong>
          </CCardHeader>
          <CCardBody>
            <div class="form-group col-md-7">
              <label for="">Diario</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeCategory($event)"
              >
                <option
                  v-for="item in arrCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Paquetes</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeSubCategory($event)"
              >
                <option
                  v-for="item in arrSubCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Seleccione una suscripción</label>
              <select class="form-control" @change="changeSuscription($event)">
                <option value="">--Seleccionar--</option>
                <option
                  v-for="item in suscripciones"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.product }} - {{ item.id }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label>Tipo de suscriptor </label>
              <p
                v-if="isTipoSuriptor"
                style="
                  color: red;
                  font-size: 0.8em;
                  margin: 0 0 5px 0;
                  padding: 0;
                "
              >
                (*)El campo suscripción está vacio
              </p>
              <select
                id="tipo_suscriptor"
                class="form-control"
                name=""
                disabled
                @change="selectedsuscriptor($event)"
              >
                <option
                  v-for="item in arrTipoSuscrip"
                  :value="item.name"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Dia del incidente</label>
              <input
                type="date"
                class="form-control"
                disabled
                id="dia_incidente"
                v-model="dateIncident"
                @change="changeDateIncident($event)"
              />
              <hr />
            </div>
            <!-- Nombre establecimiento -->
            <div class="form-group col-md-7">
              <label for="">Establecimiento</label>
              <p
                v-if="isEstablecimiento"
                style="
                  color: red;
                  font-size: 0.8em;
                  margin: 0 0 5px 0;
                  padding: 0;
                "
              >
                (*)Falta Ingresar el establecimiento
              </p>
              <input
                v-model="establecimiento"
                type="text"
                class="form-control"
                placeholder="Tottus, Restaurantes, etc"
                id="establecimiento"
                disabled
              />
            </div>
            <!--Promocion no añlicada -->
            <div class="form-group col-md-7">
              <label for="">Promoción no aplicada</label>
              <p
                v-if="isPromocion"
                style="
                  color: red;
                  font-size: 0.8em;
                  margin: 0 0 5px 0;
                  padding: 0;
                "
              >
                (*)Falta ingresar datos
              </p>
              <input
                v-model="promocion"
                type="text"
                class="form-control"
                placeholder="No se aplico 30% de descuento"
                id="promocion"
                disabled
              />
            </div>
            <!--Correo -->
            <div class="form-group col-md-7">
              <label for="">Correo</label>
              <p
                v-if="isCorreo"
                style="
                  color: red;
                  font-size: 0.8em;
                  margin: 0 0 5px 0;
                  padding: 0;
                "
              >
                (*)Falta ingresar tu correo de contacto
              </p>
              <input
                v-model="correo"
                type="text"
                class="form-control"
                placeholder="correo@gmail.com"
                id="correo"
                disabled
              />
            </div>
            <!--Observacion -->
            <div class="form-group col-md-7">
              <label for="">Observación</label>
              <p
                v-if="isObservacion"
                style="
                  color: red;
                  font-size: 0.8em;
                  margin: 0 0 5px 0;
                  padding: 0;
                "
              >
                (*)Falta colocar alguna observación
              </p>
              <input
                v-model="observacion"
                type="text"
                class="form-control"
                placeholder="observación"
                id="observacion"
                disabled
              />
            </div>
            <!-- Registrar freshdesk -->
            <div class="form-group col-md-7">
              <button
                id="boton"
                disabled
                type="button"
                class="btn-comercio btn__ingresar"
                @click="registrarFreshdesk()"
              >
                Enviar
              </button>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <img class="avatar__alicia" src="../../assets/img/nueva_alicia_pages_all.png" />
    <img class="avatar__alicia__mobile" src="../../assets/img/nueva_alicia_pages_all.png" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una suscripción",
      suscriptionSelected: {},
      checked: false,
      categoryId: "",
      subCategoryId: "",
      selectedCategoria: "0",
      tipoSuriptor: "",
      dateIncident: "",
      establecimiento: "",
      promocion: "",
      correo: "",
      observacion: "",
      idSuscripcion: "",
      nombrePackete: "",

      show: true,
      isCollapsed: true,
      isTipoSuriptor: false,
      isEstablecimiento: false,
      isPromocion: false,
      isCorreo: false,
      isObservacion: false,

      isLoading: false,
      fullPage: true,
      arrCategory: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "El Comercio" },
        { id: "2", name: "Gestión" },
      ],
      arrTipoSuscrip: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Suscriptor" },
        { id: "2", name: "Beneficiario" },
      ],
      arrSubCategory: [{ id: "", name: "--Seleccionar--" }],
    };
  },
  components: {
    Loading,
  },
  methods: {
    changeCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.categoryId = id;
      vm.isLoading = true;

      if (id == "") {
        vm.arrSubCategory = [{ id: "", name: "--Seleccionar--" }];
      } else if (id == "1") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "1", name: "El Comercio" },
          { id: "2", name: "El Comercio Impreso + Digital" },
          { id: "3", name: "El Comercio Digital" },
        ];
      } else if (id == "2") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "4", name: "Gestión" },
          { id: "5", name: "Gestión Impreso + Digital" },
          { id: "6", name: "Gestión Digital" },
        ];
      }
      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.isLoading = false;
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";
      document.getElementById("tipo_suscriptor").disabled = true;
      vm.isLoading = false;
    },
    changeSubCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.subCategoryId = id;
      vm.isLoading = true;

      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      if (id == "") {
        vm.isLoading = false;
      } else {
        let data = {
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          categoria: vm.categoryId,
          subcategoria: id,
          canal: "7",
        };
        console.log("otra data", data);
        axios
          .post(`${process.env.VUE_APP_ROOT_API}/listSuscripciones`, data)
          .then(function (res) {
            console.log("Data =>", res);
            vm.isLoading = false;
            if (res.data.data.code == "1") {
              vm.suscripciones = res.data.data.landing;
              console.log("suscripciones", vm.suscripciones);
              /* document.getElementById("tipo_suscriptor").disabled = true;
              document.getElementById("dia_incidente").disabled = true;
              document.getElementById("establecimiento").disabled = true;
              document.getElementById("promocion").disabled = true;
              document.getElementById("correo").disabled = true;
              document.getElementById("observacion").disabled = true;
              document.getElementById("boton").disabled = true;  */
            } else {
              vm.isLoading = false;
              vm.$fire({
                title: "Información",
                text: "No tiene suscripciones en este paquete.",
                type: "info",
                timer: 4000,
              }).then((r) => {});
              /*  document.getElementById("tipo_suscriptor").disabled = true;
              document.getElementById("dia_incidente").disabled = true;
              document.getElementById("establecimiento").disabled = true;
              document.getElementById("promocion").disabled = true;
              document.getElementById("correo").disabled = true;
              document.getElementById("observacion").disabled = true;
              document.getElementById("boton").disabled = true; */
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "No tiene suscripciones en este paquete.",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
    changeSuscription(event) {
      let vm = this;
      vm.idSuscripcion = event.target.value;
      vm.isLoading = true;
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      if (vm.idSuscripcion == "") {
        vm.isLoading = false;
      } else {
        let data = {
          idsuscripcion: vm.idSuscripcion,
          subcategoria: vm.subCategoryId,
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          platformOrigin: "Landing",
          queryType: "1",
        };

        axios
          .post(`${process.env.VUE_APP_ROOT_API}/consultaPaquete`, data)
          .then(function (res) {
            console.log(res);
            vm.isLoading = false;
            vm.suscriptionSelected = res.data.data;

            if (vm.suscriptionSelected["productos"]) {
              vm.nombrePackete = vm.suscriptionSelected.productos;
              document.getElementById("tipo_suscriptor").disabled = false;
              vm.nameSuscription = `La suscripción que ha seleccionado es: ${vm.suscriptionSelected["productos"]}`;
            } else {
              vm.nameSuscription = `Aún no ha seleccionado una suscripción`;
            }
          });
      }
    },
    selectedsuscriptor(event) {
      let vm = this;
      vm.tipoSuriptor = event.target.value;
      document.getElementById("dia_incidente").disabled = false;
    },
    changeDateIncident(event) {
      let vm = this;
      vm.diaIncidente = event.target.value;
      document.getElementById("establecimiento").disabled = false;
      document.getElementById("promocion").disabled = false;
      document.getElementById("correo").disabled = false;
      document.getElementById("observacion").disabled = false;
      document.getElementById("boton").disabled = false;
    },
    async registrarFreshdesk() {
      let vm = this;
      vm.isEstablecimiento = false;
      vm.isPromocion = false;
      vm.isCorreo = false;
      vm.isObservacion = false;

      if (vm.establecimiento == "") {
        vm.isEstablecimiento = true;
      }
      if (vm.promocion == "") {
        vm.isPromocion = true;
      }
      if (vm.observacion == "") {
        vm.isObservacion = true;
      }

      //validaciones de campos

      if (
        vm.isEstablecimiento == false &&
        vm.isPromocion == false &&
        vm.isObservacion == false &&
        vm.isCorreo == false
      ) {
        if (
          !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            vm.correo
          )
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "La dirección de correo es incorrecta.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else {
          //registrar en freshdesk
          let data = {
            tipoDocumento: localStorage.getItem("tipoDocumento"),
            numeroDocumento: localStorage.getItem("numeroDocumento"),
            idSuscripcion: vm.idSuscripcion,
            nombrePackete: vm.nombrePackete,
            tipoSuscriptor: vm.tipoSuriptor,
            diaIncidente: vm.dateIncident,
            establecimiento: vm.establecimiento,
            promocion: vm.promocion,
            correoSuscriptor: vm.correo,
            observacion: vm.observacion,
            platformOrigin: "Landing",
          };

          console.log("Data =>", data);

          await axios
            .post(
              `${process.env.VUE_APP_ROOT_API}/freshdesk/reclamo-club-landing`,
              data
            )
            .then(function (res) {
              vm.isLoading = false;
              if (res.data.data.code == 1) {
                vm.$fire({
                  title: `Datos actualizados correctamente​​`,
                  html: `${res.data.data.message} <br/><br/> Gracias por escribirnos.`,
                  type: "success",
                  timer: 6000,
                }).then((r) => {});

                vm.tipoSuriptor = "";
                vm.dateIncident = "";
                vm.establecimiento = "";
                vm.promocion = "";
                vm.correo = "";
                vm.observacion = "";
              } else {
                vm.$fire({
                  title: "Seguridad",
                  html: res.data.data.message,
                  type: "info",
                  timer: 40000,
                }).then((r) => {
                  console.log("dismin", r);
                });
              }
            })
            .catch((error) => {
              vm.isLoading = false;
              vm.$fire({
                title: "Error",
                text: "Ocurrio un error en el servidor",
                type: "error",
                timer: 6000,
              }).then((r) => {});
            });
          //fin de registro en freshdesk
        }
      }
    },
  },
  filters: {
    currency(value) {
      return `S/ ${value}`;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>
